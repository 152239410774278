import React, { FunctionComponent, useCallback, useContext, useEffect, useState } from 'react'

import * as Sentry from '@sentry/react'
import { ErrorBoundary } from '@sentry/react'
import { ApolloError } from 'apollo-client'
import { debug } from 'loglevel'
import { hot } from 'react-hot-loader/root'
import { useTranslation } from 'react-i18next'
import { useModal } from 'react-modal-hook'
import LoadingBar from 'react-top-loading-bar'
import { useTheme } from 'styled-components'

import { MIN_ROWS } from './config'
import { BatchContext } from './context/batch.context'
import { EnvironmentContext } from './context/environment.context'
import { MetricStatsContext } from './context/metric.stats.context'
import { SettingsContext } from './context/settings.context'
import { ErrorFallback } from './fragments/ErrorFallback'
import { ConfirmModal } from './fragments/modals'
import { SecondaryLoader } from './fragments/SecondaryLoader'
import { Spinner } from './fragments/spinner'
import { useDataSourceSelectionFlow } from './hooks/useDataSourceSelectionFlow'
import { useErrorModal } from './hooks/useErrorModal'
import { Batch } from './lib/batch'
import { ERROR_CODE, FileParser } from './lib/file.parser'
import { Recipe } from './lib/recipe'
import { View } from './lib/view'
import { ITheme } from './theme'
import { IBatchConfig, IInputObject } from './types/settings.interface'
import { useListener } from './utils/event.manager'
import { detach } from './utils/functions'
import { makeProgressTracker, useProgress } from './utils/progress.tracker'
import { HeaderCheckView } from './views/header.check.view'
import { InputView } from './views/input.view'
import { MatchView } from './views/match.view'

const progressBar = makeProgressTracker()
const $Controller: FunctionComponent<IControllerProps> = ({ batchConfig }) => {
  const { license } = useContext(EnvironmentContext)
  const { batch, setBatch } = useContext(BatchContext)
  const [loading, setLoading] = useState(true)
  const [secondaryLoading, setSecondaryLoading] = useState(false)

  const theme = useTheme() as ITheme
  const { t } = useTranslation()
  const settings = useContext(SettingsContext)
  const [encoding, setEncoding] = useState(settings.encoding)
  const [detectedEncoding, setDetectedEncoding] = useState('')
  const [activeStage, setActiveStage] = useState<STAGE>(STAGE.INITIAL)
  const selectDataSourceFlow = useDataSourceSelectionFlow()
  if (!license) {
    throw new Error('License must exist')
  }

  const statsInstance = useContext(MetricStatsContext)
  if (!statsInstance) {
    throw new Error('Stats instance must exist')
  }

  useListener(
    'do/corrections',
    ({ payload }) => {
      if (batch) {
        batch.view.validator.updateSourceAndValidateState(batch.view, payload)
      }
    },
    [batch]
  )

  const resetBatch = useCallback(() => {
    setBatch(undefined)
    setTimeout(() => {
      const recipe = new Recipe(settings, batchConfig)
      recipe.generateStubRules()
      const manualView = View.makeFromRecipe(batchConfig, recipe, MIN_ROWS)
      setBatch(new Batch(batchConfig, license, manualView, settings, statsInstance))
    }, 20)
  }, [setBatch])

  const [showErrorModal] = useErrorModal()

  useEffect(
    () =>
      detach(async () => {
        const recipe = new Recipe(settings, batchConfig)
        if (batchConfig.source && batchConfig.source.length) {
          let b
          if (typeof batchConfig.source === 'string') {
            b = new Batch(
              batchConfig,
              license,
              new FileParser(batchConfig.source, settings),
              settings,
              statsInstance
            )
          } else {
            const manualView = View.makeFromInputObjects(
              batchConfig,
              batchConfig.source as IInputObject[],
              recipe
            )
            b = new Batch(batchConfig, license, manualView, settings, statsInstance)
          }

          await b.init()
          setBatch(b)

          if (!b.parser) {
            setActiveStage(STAGE.AUDIT)
            setSecondaryLoading(false)
          } else if (b.getParser().headersSet) {
            setActiveStage(STAGE.MATCH)
            setSecondaryLoading(false)
          } else {
            setActiveStage(STAGE.HEADER_CHECK)
            setSecondaryLoading(false)
          }
        } else if (!batch) {
          resetBatch()
        }
        setLoading(false)
      }),
    [batchConfig?.source]
  )

  const rewindStage = useCallback(
    (verify = false) => {
      if (verify) {
        switch (activeStage) {
          case STAGE.INITIAL:
          case STAGE.HEADER_CHECK:
            resetBatch()
            setActiveStage(STAGE.INITIAL)
            break
          case STAGE.MATCH:
            batch?.getParser().reset()
            setActiveStage(STAGE.HEADER_CHECK)
            break
          case STAGE.AUDIT:
            batch?.view?.clearUserEdits()
            batch?.recipe?.removeVirtualFields()
            setActiveStage(STAGE.MATCH)
            break
        }
      } else {
        confirmRewind()
      }
    },
    [activeStage]
  )

  const [confirmRewind, hideRewindModal] = useModal(
    () => (
      <ConfirmModal
        msg={t('clearAllChanges')}
        onConfirm={() => {
          rewindStage(true)
          hideRewindModal()
        }}
        onDismiss={hideRewindModal}
      />
    ),
    [rewindStage]
  )

  const progress = useProgress(progressBar)

  useEffect(() => {
    Sentry.addBreadcrumb({
      category: 'stage',
      message: `User moved to stage: ${activeStage}`,
      level: Sentry.Severity.Info
    })
    statsInstance.setUserFocus(`IMPORT.STAGE.${activeStage}`)
  }, [activeStage])

  if (loading || !batch) {
    return <Spinner />
  }

  return (
    <ErrorBoundary fallback={ErrorFallback}>
      {progress !== null && (
        <div className={`iterator-loading large-loader`}>
          <LoadingBar
            progress={progress}
            className='loading-line'
            height={4}
            color={theme?.iterator?.barColor ?? 'rgba(255,255,255,0.5)'}
          />
          <div className='loading-label-wrapper'>
            <span className={'loading-label'}>{Math.floor(100 - (progress || 0))}% remaining</span>
          </div>
        </div>
      )}
      <div className='active-stage borderColor'>
        {secondaryLoading && <SecondaryLoader />}
        {[STAGE.INITIAL, STAGE.AUDIT].includes(activeStage) && (
          <InputView
            batch={batch}
            stage={activeStage}
            encoding={encoding ?? detectedEncoding}
            onFile={async (parser) => {
              setSecondaryLoading(true)
              Sentry.addBreadcrumb({
                category: 'file',
                message: 'User selected file',
                data: {
                  fileName: parser.fileName,
                  fileSize: parser.fileSize,
                  fileType: parser.fileType
                },
                level: Sentry.Severity.Info
              })

              const errors = await parser.checkLimits()

              if (typeof errors === 'undefined') {
                const localBatch = new Batch(batchConfig, license, parser, settings, statsInstance)
                await localBatch.init()

                if (!(await localBatch.verifyStepChange(STEP.UPLOAD))) {
                  setSecondaryLoading(false)
                  return
                }

                setBatch(localBatch)

                if (localBatch.getParser().headersSet) {
                  if (typeof (await localBatch.getParser().checkLimits()) !== 'undefined') {
                    setActiveStage(STAGE.HEADER_CHECK)
                    setSecondaryLoading(false)
                  } else {
                    if (!(await localBatch.verifyStepChange(STEP.MATCH))) {
                      setActiveStage(STAGE.HEADER_CHECK)
                      setSecondaryLoading(false)
                      return
                    }
                    setActiveStage(STAGE.MATCH)
                    setSecondaryLoading(false)
                  }
                } else {
                  setActiveStage(STAGE.HEADER_CHECK)
                  setSecondaryLoading(false)
                }
              } else {
                showErrorModal(
                  errors === ERROR_CODE.MAX_ROWS
                    ? t('errors.maxRecords', { maxRecords: settings.maxRecords })
                    : t('errors.noRecords')
                )
                setSecondaryLoading(false)
              }
              setDetectedEncoding(await parser.detectEncoding())
            }}
            onNonParseableFile={async (file) => {
              setSecondaryLoading(true)
              Sentry.addBreadcrumb({
                category: 'file',
                message: 'User selected file that must be processed server-side',
                data: {
                  fileName: file.name,
                  fileSize: file.size,
                  fileType: file.type
                },
                level: Sentry.Severity.Info
              })

              const {
                id: batchId,
                writeAccessKey,
                error
              } = await Batch.createManagedBatchOnServer(license, batchConfig, file.name, settings)

              if (error) {
                setSecondaryLoading(false)
                throw error
              }

              if (!batchId) {
                setSecondaryLoading(false)
                throw new Error('batchId must be present')
              }

              const result = await selectDataSourceFlow(license, file, batchId)

              if (!result || !result.final) {
                setSecondaryLoading(false)
                return
              }

              const { final, styles } = result
              const fileParser = new FileParser(final, settings)
              const errors = await fileParser.checkLimits()

              if (typeof errors !== 'undefined') {
                setSecondaryLoading(false)
                return showErrorModal(
                  errors === ERROR_CODE.MAX_ROWS
                    ? t('errors.maxRecords', { maxRecords: settings.maxRecords })
                    : t('errors.noRecords')
                )
              }

              const localBatch = new Batch(
                batchConfig,
                license,
                fileParser,
                settings,
                statsInstance,
                batchId,
                writeAccessKey
              )

              if (styles) {
                localBatch.view.styles = styles
              }

              await localBatch.initLocal()

              if (!(await localBatch.verifyStepChange(STEP.UPLOAD))) {
                setSecondaryLoading(false)
                return
              }

              setBatch(localBatch)

              if (localBatch.getParser().headersSet) {
                if (!(await localBatch.verifyStepChange(STEP.MATCH))) {
                  setActiveStage(STAGE.HEADER_CHECK)
                  setSecondaryLoading(false)
                  return
                }
                setActiveStage(STAGE.MATCH)
                setSecondaryLoading(false)
              } else {
                setActiveStage(STAGE.HEADER_CHECK)
                setSecondaryLoading(false)
              }
            }}
            onComplete={() =>
              batch.finalSubmit(progressBar, activeStage).catch((err) => {
                if (err instanceof ApolloError) {
                  const message = `Error: ${err.graphQLErrors
                    ?.map((error) => error.message)
                    .join(',')}`
                  return showErrorModal(message)
                }
                showErrorModal('Something went wrong.')
              })
            }
            onCancel={() => rewindStage()}
          />
        )}
        {STAGE.HEADER_CHECK === activeStage && (
          <HeaderCheckView
            onComplete={async () => {
              setSecondaryLoading(true)
              if (
                !(await batch
                  .verifyStepChange(STEP.MATCH)
                  .finally(() => setSecondaryLoading(false)))
              ) {
                return
              }

              setActiveStage(STAGE.MATCH)
            }}
            {...(typeof batchConfig.source === 'string'
              ? {}
              : {
                  onCancel: () => rewindStage()
                })}
            encodingSetting={encoding}
            encodingDetected={detectedEncoding}
            setEncoding={setEncoding}
          />
        )}
        {STAGE.MATCH === activeStage && (
          <MatchView
            onComplete={async () => {
              setSecondaryLoading(true)
              batch?.recipe.acceptVirtualFields()
              if (!(await batch?.verifyStepChange(STEP.REVIEW))) {
                setSecondaryLoading(false)
                return
              }
              debug('LOADING ALL DATA')
              await batch?.getParser().loadData()
              debug('LOADED ALL DATA')
              await batch?.validator.validateState(batch.view)
              debug('VALIDATION COMPLETE')
              detach(batch.recordMilestoneMatched())
              setSecondaryLoading(false)
              setActiveStage(STAGE.AUDIT)
            }}
            onCancel={() => rewindStage()}
          />
        )}
      </div>
    </ErrorBoundary>
  )
}

$Controller.displayName = 'Controller'
export const Controller = hot($Controller)

export enum STAGE {
  INITIAL = 'INITIAL',
  HEADER_CHECK = 'HEADER_CHECK',
  MATCH = 'MATCH',
  AUDIT = 'AUDIT'
}

export enum STEP {
  UPLOAD = 'upload',
  MATCH = 'match',
  REVIEW = 'review',
  MATCH_FIELD = 'match_field'
}

interface IControllerProps {
  batchConfig: IBatchConfig
}
