import { OptionsType } from 'react-select'

import {
  IDataHookResponse,
  IDictionary,
  IPrimitive,
  IPrimitiveDictionary,
  IStringDictionary,
  Nullable,
  ScalarDictionary
} from '../types/general.interface'
import {
  IBatchConfig,
  ICustomField,
  ICustomFieldOption,
  IField,
  IFieldSelect,
  ISettings,
  IVirtualField,
  IVirtualFieldOptions
} from '../types/settings.interface'
import { emitAndWait, hasListeners, listen, removeAllListeners } from '../utils/event.manager'
import { convertToLetters, readCache, writeCache } from '../utils/functions'
import { validateVirtualField } from '../utils/settings.helpers'
import { FileParser, IndexTuple } from './file.parser'
import { MatchFinder } from './match.finder'

export class Recipe {
  private virtualFields: IVirtualField[] = []
  private customFields: ICustomField[] = []
  private customOptions: Record<string, ICustomFieldOption[]> = {}
  private _rules: IRule[] = []
  private workingRules: IRule[] = []
  private recs: IRule[] = []
  private parser?: FileParser

  constructor(private settings: ISettings, private batchConfig: IBatchConfig) {}

  get rules() {
    return this._rules
  }

  set rules(rules) {
    this._rules = rules
    let workingRules = rules
    if (this.virtualFields?.length) {
      workingRules = workingRules
        .filter(
          (rule, index, self) => self.findIndex((r) => r.sourceIndex === rule.sourceIndex) === index
        )
        .sort((a) => (a.virtual ? 1 : -1))
    }

    this.workingRules = workingRules
      .filter(Recipe.isWorkingRule)
      .sort((a, b) => (a.order ?? a.sourceIndex + 0.1) - (b.order ?? b.sourceIndex + 0.1))
  }

  public getRules(): IRule[] {
    return this.rules
  }

  public getRealRules(): IRule[] {
    return this.rules.filter((v) => !v.virtual)
  }

  public getVirtualRules(): IRule[] {
    return this.rules.filter((v) => v.virtual)
  }

  public removeVirtualFields(): void {
    this.rules = [...this.rules].filter((v) => !v.virtual)
    this.virtualFields = []
  }

  public acceptVirtualFields(): void {
    listen('do/addVirtualField', ({ payload }) => {
      if (!this.batchConfig.features?.virtualFields) {
        // tslint:disable-next-line: no-console
        console.info('[Flatfile] Contact Flatfile to get access to `addVirtualField()`')
        return
      }

      if (!validateVirtualField(payload)) {
        return
      }

      if (this.virtualFields.some((v) => v.key === payload.field.key)) {
        // tslint:disable-next-line: no-console
        console.error(
          `[Flatfile] Invalid virtual field: Target key "${payload.field.key}" has already been used.`
        )
        return
      }

      this.addVirtualField(
        {
          ...payload.field,
          virtual: true
        },
        payload.options
      )
    })
  }

  public rejectVirtualFields(): void {
    removeAllListeners('do/addVirtualField')
  }

  public getSettings(): ISettings {
    return this.settings
  }

  public getFieldsAsOptions(): OptionsType<IStringDictionary> {
    const extractOption = ({ label, key: value }: IField | ICustomField) => ({
      label: label || value,
      value
    })

    return this.customFields.map(extractOption).concat(this.settings.fields.map(extractOption))
  }

  public getWorkingRules(): IRule[] {
    return this.workingRules
  }

  public readableSourceNames(rule: IRule[], fallback?: true): string[]
  public readableSourceNames(rule: IRule[], fallback: boolean): (string | undefined)[]
  public readableSourceNames(
    providedRules?: IRule[],
    fallback: boolean = true
  ): (string | undefined)[] {
    const rules = providedRules || this.getRules()
    const headers = this.parser && this.parser.getHeaders()
    if (headers) {
      return rules.map(({ sourceIndex }) => {
        const header = headers.find(([, i]) => sourceIndex === i)
        return header ? header[0] : fallback ? convertToLetters(sourceIndex + 1) : undefined
      })
    }
    return rules.map(({ sourceIndex }) =>
      fallback ? convertToLetters(sourceIndex + 1) : undefined
    )
  }

  public readableSourceName(rule: IRule, fallback?: true): string
  public readableSourceName(rule: IRule, fallback: false): string | undefined
  public readableSourceName(rule: IRule, fallback: boolean = true): string | undefined {
    return this.readableSourceNames([rule], fallback)[0]
  }

  public getDuplicates(baseRule?: IRule): IRule[] {
    const [duplicates] = this.getWorkingRules().reduce(
      ([dupes, all], { targetKey, sourceIndex }) => {
        if (!targetKey || (baseRule && baseRule.targetKey !== targetKey)) {
          return [dupes, all]
        }
        if (all.includes(targetKey) && !this.getFieldSettings(sourceIndex)?.allowMultipleColumns) {
          return [[...dupes, targetKey], all]
        } else {
          return [dupes, [...all, targetKey]]
        }
      },
      [[], []] as ListTuple
    )
    return this.getWorkingRules().filter(
      ({ targetKey, sourceIndex }) =>
        targetKey &&
        duplicates.includes(targetKey) &&
        (!baseRule || baseRule.sourceIndex !== sourceIndex)
    )
  }

  public isFieldRequired(field: IField, matchedKeys: string[]) {
    return field?.validators?.some((validator) => {
      const matched = (key: string) => matchedKeys.includes(key)
      const notMatched = (key: string) => !matchedKeys.includes(key)
      switch (validator.validate) {
        case 'required':
          return true
        case 'required_with':
          return validator.fields.some(matched)
        case 'required_with_all':
          return validator.fields.every(matched)
        case 'required_without':
          return validator.fields.some(notMatched)
        case 'required_without_all':
          return validator.fields.every(notMatched)
        case 'required_with_values':
          return Object.keys(validator.fieldValues).some(matched)
        case 'required_with_all_values':
          return Object.keys(validator.fieldValues).every(matched)
        case 'required_without_values':
          return Object.keys(validator.fieldValues).some(notMatched)
        case 'required_without_all_values':
          return Object.keys(validator.fieldValues).every(notMatched)
        default:
          return false
      }
    })
  }

  public getMissingRequiredFields(): IField[] {
    const existingMatchedTargetKeys = this.getWorkingRules().reduce(
      (acc, { targetKey }) => acc.concat([...(targetKey ? [targetKey] : [])]),
      [] as string[]
    )

    const requiredFields = this.settings.fields.filter((field) =>
      this.isFieldRequired(field, existingMatchedTargetKeys)
    )

    return requiredFields.filter(({ key }) => !existingMatchedTargetKeys.includes(key))
  }

  public getFieldSettings(srcIndex: number): IField | undefined {
    const rule = this.rules.find(({ sourceIndex }) => sourceIndex === srcIndex)
    if (!rule) {
      throw new Error('No rule found for this column.')
    }
    if (!rule.targetKey) {
      return undefined
    }
    return this.getField(rule.targetKey)
  }

  public hasField(key: string): boolean {
    return (
      this.customFields.some((f) => f.key === key) ||
      this.settings.fields.some((f) => f.key === key)
    )
  }

  public getField(key: string): IField | ICustomField | IVirtualField {
    const virtualField = this.virtualFields.find((f) => f.key === key)

    if (virtualField) {
      return virtualField
    }

    const customField = this.customFields.find((f) => f.key === key)

    if (customField) {
      return customField
    }

    const field = this.settings.fields.find((f) => f.key === key)

    if (field) {
      if (field.type === 'select' && this.customOptions?.[field.key]?.length) {
        return {
          ...field,
          options: field.options.concat(this.customOptions[field.key])
        }
      }

      return field
    }

    throw new Error('Field does not exist')
  }

  public addCustomField(key: string) {
    if (this.hasField(key)) {
      throw new Error(`Field like "${key}" already exists`)
    }

    this.customFields.push({ custom: true, key, type: 'string', label: key })
  }

  public getFieldLabels(keys: string[]): string[] {
    const keyToLabel: Record<string, string> = {}

    this.settings.fields.forEach((item) => {
      if (item.label) {
        keyToLabel[item.key] = item.label
      }
    })

    return keys.map((key) => (key in keyToLabel ? keyToLabel[key] : key))
  }

  public async modifyRule(
    sourceIndex: number,
    targetKey?: string,
    verify?: (r: IRule) => Promise<boolean>
  ): Promise<void> {
    const [rule, i] = this.getRule(sourceIndex)
    const newRules = [...this.rules]
    const newRule: IRule = { ...rule, targetKey }
    if (targetKey) {
      const field = this.getField(targetKey)
      newRule.isCustom = 'custom' in field && field.custom
      newRule.targetType = field.type
      if (field.type === 'select') {
        const cache = (readCache('options-match', field.key) || {}) as ScalarDictionary
        const matcher = new MatchFinder(this.parser as FileParser, this.settings)
        const optionsMap = matcher.getDefaultMatches(i, field, cache)

        if (typeof verify === 'function' && !(await verify({ ...newRule, optionsMap }))) {
          return
        }

        newRules[i] = { ...newRule, optionsMap }
        writeCache('options-match', field.key, optionsMap)
        this.rules = newRules
        return
      } else {
        delete newRule.optionsMap
      }
    }

    if (typeof verify === 'function' && !(await verify(newRule))) {
      return
    }

    newRules[i] = newRule
    this.rules = newRules
  }

  public modifyStatus(sourceIndex: number, status: RULE_STATUS): void {
    const [rule, i] = this.getRule(sourceIndex)
    const newRules = [...this.rules]
    newRules[i] = { ...rule, status }
    this.rules = newRules
  }

  public changeOptionsMatch(
    srcIndex: number,
    srcValue: string,
    targetValue?: Nullable<IPrimitive>
  ): void {
    const [rule, ruleIndex] = this.getRule(srcIndex)
    const newRules = [...this.rules]

    if (typeof targetValue === 'undefined') {
      delete rule.optionsMap?.[srcValue]
      rule.optionsMap = Object.assign({}, rule.optionsMap)
    } else {
      rule.optionsMap = {
        ...rule.optionsMap,
        [srcValue]: targetValue
      }
    }

    writeCache('options-match', rule.targetKey as string, rule.optionsMap)
    newRules[ruleIndex] = rule
    this.rules = newRules
  }

  public addCustomOptionsMatch(srcIndex: number, srcValue: string) {
    const [rule] = this.getRule(srcIndex)

    if (!rule.targetKey) {
      return
    }

    const { options } = this.getField(rule.targetKey) as IFieldSelect

    if (!options.find((o) => o.value === srcValue)) {
      this.customOptions[rule.targetKey] = (this.customOptions[rule.targetKey] || []).concat([
        {
          value: srcValue,
          label: srcValue,
          custom: true
        }
      ])
    }

    this.changeOptionsMatch(srcIndex, srcValue, srcValue)
  }

  /**
   * @param parser
   * @param force
   */
  public async generateInitialRules(parser: FileParser, force: boolean = false): Promise<void> {
    this.parser = parser
    const cached = await this.loadCachedRules(parser)
    if (cached && !force) {
      cached.forEach((v) => {
        if (v.targetKey) {
          const field = this.settings.fields.find((f) => f.key === v.targetKey)

          if (!field) {
            delete v.targetKey
            delete v.targetType
          } else if (field.type === 'select' && v.optionsMap) {
            Object.entries(v.optionsMap).forEach(([key, value]) => {
              if (!field.options.find((o) => o.value === value)) {
                delete v.optionsMap![key]
              }
            })
          }
        }
      })
    }
    const matcher = new MatchFinder(parser, this.settings)
    const recs = await matcher.getRecommendedMatches()

    const rules = recs.map((rec) => {
      if (this.actualType(rec.newName) === 'select') {
        const field = this.getField(this.actualKey(rec.newName) as string)

        if (rec.columnIndex != null && 'options' in field) {
          const optionsMap = matcher.getDefaultMatches(rec.columnIndex, field)
          return {
            sourceIndex: rec.columnIndex as number,
            targetKey: this.actualKey(rec.newName),
            targetType: this.actualType(rec.newName),
            status: RULE_STATUS.PENDING,
            optionsMap
          }
        }
      }

      return {
        sourceIndex: rec.columnIndex as number,
        targetKey: this.actualKey(rec.newName),
        targetType: this.actualType(rec.newName),
        status: RULE_STATUS.PENDING
      }
    })

    let rulesWithCache = rules.map((r) => {
      const c = cached?.find(
        (cr) =>
          cr.sourceIndex !== undefined &&
          cr.sourceIndex === r.sourceIndex &&
          (cr.targetKey || cr.status === RULE_STATUS.IGNORED)
      )
      if (c) {
        if (c.targetKey && this.actualType(c.targetKey) === 'select') {
          return {
            ...c,
            targetType: this.actualType(c.targetKey),
            optionsMap: Object.assign({}, r.optionsMap, c.optionsMap)
          }
        }
        return { ...c, targetType: c.targetKey ? this.actualType(c.targetKey) : undefined }
      } else if (cached?.find((cr) => cr.targetKey === r.targetKey)) {
        return { ...r, targetKey: undefined, targetType: undefined }
      } else {
        return r
      }
    })

    const ignoreColumns =
      this.parser
        .getHeaders()
        ?.filter(([header]) =>
          this.settings.ignoreColumns?.find((col) => col.toLowerCase() === header.toLowerCase())
        )
        ?.map(([_, sourceIndex]) => sourceIndex) || []

    if (ignoreColumns.length) {
      rulesWithCache = rulesWithCache.map((rule) => {
        if (ignoreColumns.includes(rule.sourceIndex)) {
          return {
            ...rule,
            status: RULE_STATUS.IGNORED,
            editable: false
          }
        }

        return rule
      })
    }

    this.rules = rulesWithCache
    this.recs = rulesWithCache.map((rule) => ({ ...rule }))
  }

  public generateStubRules() {
    this.rules = this.settings.fields.map((field, i) => ({
      sourceIndex: i,
      targetKey: field.key,
      targetType: field.type,
      status: RULE_STATUS.ACCEPTED
    }))
  }

  public generateDirectRules(sample: IPrimitiveDictionary) {
    const keys = Object.keys(sample)
    this.rules = this.settings.fields.map((field, i) => ({
      sourceIndex: i,
      targetKey: field.key,
      targetType: field.type,
      status: keys.includes(field.key) ? RULE_STATUS.ACCEPTED : RULE_STATUS.IGNORED
    }))
  }

  public getRecommendation(sourceIndex: number): IRule {
    const index = this.recs.findIndex((r) => r.sourceIndex === sourceIndex)
    if (index === -1) {
      throw new Error('Invalid source index')
    }
    return this.rules[index]
  }

  public refreshRule(rule: IRule): IRule {
    const [newRule] = this.getRule(rule.sourceIndex)
    return newRule
  }

  public async markReviewed(): Promise<void> {
    await this.updateCachedRules()
  }

  public getRule(sourceIndex: number): IndexTuple<IRule> {
    const index = this.rules.findIndex((r) => r.sourceIndex === sourceIndex)
    if (index === -1) {
      throw new Error('Invalid source index')
    }
    return [this.rules[index], index]
  }

  public getRuleByKeyOrFail(key: string): IndexTuple<IRule> {
    const index = this.rules.findIndex((r) => r.targetKey === key)
    if (index === -1) {
      throw new Error('Invalid key')
    }
    return [this.rules[index], index]
  }

  public getRuleByKey(key: string): undefined | IndexTuple<IRule> {
    const index = this.rules.findIndex((r) => r.targetKey === key)
    if (index === -1) {
      return undefined
    }
    return [this.rules[index], index]
  }

  public getWorkingRuleAt(sequenceIndex: number): IRule {
    const rules = this.getWorkingRules()
    if (rules[sequenceIndex] === undefined) {
      throw new Error(`No rule related to table index: ${sequenceIndex}`)
    }
    return rules[sequenceIndex]
  }

  public async runRecordHook(data: ScalarDictionary, sequence: number): Promise<IDataHookResponse> {
    if (!hasListeners('record:change')) {
      throw new Error('No record hook available to call.')
    }
    return emitAndWait('record:change', { data, sequence })
  }

  public hasRecordHook(): boolean {
    return hasListeners('record:change')
  }

  private addVirtualField(virtualField: IVirtualField, options: IVirtualFieldOptions = {}): void {
    const sourceIndex =
      this.getRealRules().find((v) => v.targetKey === virtualField.key)?.sourceIndex ??
      [...this.rules].sort((a, b) => a.sourceIndex - b.sourceIndex).slice(-1)[0].sourceIndex + 1

    this.rules = this.rules.concat([
      {
        sourceIndex,
        targetKey: virtualField.key,
        targetType: virtualField.type ?? undefined,
        status: 0,
        virtual: true,
        ...options
      }
    ])
    this.virtualFields.push(virtualField)
  }

  // TODO: have this do a server lookup too
  private async loadCachedRules(parser: FileParser): Promise<IRule[] | undefined> {
    const hash = parser.getHash()
    if (!hash) {
      return Promise.resolve(undefined)
    }
    return readCache<IRule[]>(`${this.settings.type}:${hash}`, 'rules')
  }

  // TODO: have this do a server save
  private async updateCachedRules(): Promise<void> {
    const hash = this.parser?.getHash()
    if (!hash) {
      return
    }
    return writeCache(
      `${this.settings.type}:${hash}`,
      'rules',
      [...this.rules]
        // skip `editable` prop
        .map(({ editable, ...rule }, index, arr) => {
          // don't cache multiple rules with the same target key due to error
          if (arr.findIndex((v) => v.targetKey === rule.targetKey) !== index) {
            delete rule.targetKey
          }
          return rule
        })
    )
  }

  private actualKey(str: string | undefined): undefined | string {
    if (this.settings.fields.find((f) => f.key === str)) {
      return str
    }
    return undefined
  }

  private actualType(str: string | undefined): undefined | IField['type'] {
    const field = this.settings.fields.find((f) => f.key === str)
    if (field) {
      return field.type
    }
    return undefined
  }

  public static isWorkingRule(rule?: IRule): boolean {
    return Boolean(
      rule?.targetKey &&
        (rule.status === RULE_STATUS.PENDING || rule.status === RULE_STATUS.ACCEPTED)
    )
  }
}

export interface IRule {
  sourceIndex: number
  targetKey?: string
  targetType: IField['type']
  isCustom?: boolean
  optionsMap?: IDictionary<Nullable<IPrimitive>>
  status: RULE_STATUS
  editable?: boolean
  virtual?: boolean
  order?: number
  hideFields?: string[]
}

export enum RULE_STATUS {
  PENDING,
  IGNORED,
  ACCEPTED
}

export type ListTuple<T = string> = [T[], T[]]
